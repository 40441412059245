import Accordion from 'aac-components/components/Accordion';
import {BREAKPOINT, MAX_WIDTH_PX} from 'aac-components/utils/styles';
import Image from 'next/image';
import React, {useContext} from 'react';
import AppContext from '../AppContext';

const TreatmentOptionsSection = (props) => {
    const {data: {acf: {content_tabs = []} = {}} = {}} = props;

    const {isMobile} = useContext(AppContext);

    const sectionOneAccordions =
        Array.isArray(content_tabs) && content_tabs.filter((tab, index) => index <= 3);
    const sectionTwoAccordions =
        Array.isArray(content_tabs) && content_tabs.filter((tab, index) => index >= 4);

    return (
        <section className="treatment-section">
            <div className="container">
                <div className="option__image">
                    <Image
                        src="/static/homepage-assets/treatment-options.png"
                        width={0}
                        height={0}
                        sizes="400px"
                        style={{width: '100%', height: '100%'}}
                        alt="Treatment Options Illustration"
                    />
                </div>
                <div>
                    <h2>Our Treatment Options</h2>
                    <p>
                        We offer a range of evidence-based treatment options tailored to
                        meet the unique needs of each individual.
                    </p>
                    <div className="accordion__container">
                        {sectionOneAccordions.map((section) => {
                            return (
                                <div key={section?.title}>
                                    <Accordion title={section?.title}>
                                        <div
                                            dangerouslySetInnerHTML={{
                                                __html: section?.content,
                                            }}
                                        />
                                    </Accordion>
                                </div>
                            );
                        })}
                    </div>
                </div>
            </div>
            <div className="container">
                <div className="option__image">
                    <Image
                        src="/static/homepage-assets/therapies-specialized-programs.png"
                        width={0}
                        height={0}
                        sizes="400px"
                        style={{width: '100%', height: '100%'}}
                        alt="Treatment Options Illustration"
                    />
                </div>
                <div>
                    <h2>Therapies & Specialized Programs</h2>
                    <p>
                        Our treatment centers offer specialized program types and
                        therapies to choose from to suit each person’s individualized
                        needs.
                    </p>
                    <div className="accordion__container">
                        {sectionTwoAccordions.map((section) => {
                            return (
                                <div key={section?.title}>
                                    <Accordion title={section?.title}>
                                        <div
                                            dangerouslySetInnerHTML={{
                                                __html: section?.content,
                                            }}
                                        />
                                    </Accordion>
                                </div>
                            );
                        })}
                    </div>
                </div>
            </div>
            <style jsx>
                {`
                    .treatment-section {
                        max-width: ${MAX_WIDTH_PX};
                        margin: 0 auto;
                        padding: 0 15px;
                    }

                    .container {
                        display: grid;
                        grid-template-columns: 1fr;
                        grid-gap: 40px;
                        margin-bottom: 40px;
                    }
                    @media screen and (min-width: ${BREAKPOINT}) {
                        .container {
                            grid-template-columns: 300px auto;
                            grid-gap: 80px;
                            margin-bottom: 120px;
                            align-items: center;
                        }
                    }
                    .container > div:first-child {
                        max-width: fit-content;
                        margin: 0 auto;
                    }
                    @media screen and (min-width: ${BREAKPOINT}) {
                        .container > div:first-child {
                            margin: 0;
                        }
                    }
                    .option__image {
                        position: relative;
                        min-width: 200px;
                        height: auto;
                    }
                    @media screen and (min-width: ${BREAKPOINT}) {
                        .option__image {
                            min-width: 250px;
                        }
                    }
                `}
            </style>
        </section>
    );
};
export default TreatmentOptionsSection;
